import React, { Component } from "react";
import glamorous from "glamorous";
import { HEADER_MAX_HEIGHT, FontSize } from "../styles/Globals";

const Wrapper = glamorous.div({
  padding: HEADER_MAX_HEIGHT + 40 + "px calc(15%) calc(5%) calc(15%)",
  fontSize: FontSize.Regular
});

class Privacy extends Component {
  render() {
    return (
      <Wrapper>
        <p>
          This Privacy Policy provides information about how we process personal
          information through the website{" "}
          <a href="https://www.fieldatwork.com">www.fieldatwork.com</a>{" "}
          <b>
            <span class="font-weight-bold">("Site")</span>.
          </b>
        </p>
        <p>
          The Site is operated by FieldAtWork Pty Ltd, a company incorporated
          under the laws of Australia, with a registered address at Suite 1301,
          9 Yarra Street, South Yarra, VIC 3141, Australia. FieldAtWork Limited,{" "}
          <b>
            <span class="font-weight-bold"> ("we", "us" or “FieldAtWork”)</span>
          </b>{" "}
          is committed to keeping your information secure and managing it in
          accordance with our legal responsibilities under the applicable
          privacy and data protection laws.
        </p>
        <p>
          This Privacy Policy is an integral part of our Terms of Use and
          describes our practices regarding the personal information we collect
          on and through our websites. This privacy statement also describes –
          under the heading “Client Data” – our role and practices in connection
          with personal information we may host on behalf of our Clients.
        </p>
        <p>
          By visiting our Site or using any of our services, you agree to your
          personal information being used and disclosed in the manner set out in
          this Policy. Where required by law, we will only collect personal
          information from you if you have specifically agreed to us doing so
          (for example through your acceptance of this Policy or via an
          electronic tick-box or other similar measures as required by the
          applicable privacy and data protection laws).
        </p>
        <p>
          You do not have to provide your personal information to us, but if you
          do not provide certain information it may affect the service we are
          able to provide to you.
        </p>
        <p>
          By continuing to use this Site, you agree to us, as data controllers
          collecting and using your personal information in accordance with this
          Privacy Policy and for the purposes of properly running the Site and
          offering our products and services to you.
        </p>
        <b>
          <p class="font-weight-bold">
            The personal information that we collect
          </p>
        </b>
        <p>
          The primary purpose of this Site is to provide you with information
          regarding the products and services provided by FieldAtWork. It is
          intended for business users, who may be interested in learning about
          our products and services in a professional capacity.
        </p>
        <p>
          When you use this Site, as is the case with most websites, certain
          limited information (such as your browser type and IP address) will be
          collected by us automatically.
        </p>
        <p>
          We will also collect and store certain personal and private
          information as follows:
        </p>
        <div class="pl-6">
          <b>
            <p class="font-weight-bold">
              Newsletters and other marketing collateral
            </p>
          </b>
          <p>
            If you wish to receive one of our newsletters, or download certain
            marketing content such as whitepapers or datasheets, you will need
            to provide us with your name and email address. Please see below in
            relation to marketing preferences and the sharing of this
            information.
          </p>
          <b>
            <p class="font-weight-bold">Site Surveys</p>
          </b>{" "}
          <p>
            From time to time, we may ask you to complete a user satisfaction
            survey. In completing the survey, you may provide us with personal
            information, including your name, email address and views and
            opinions.{" "}
          </p>
          <b>
            <p class="font-weight-bold">Event Registrations</p>
          </b>{" "}
          <p>
            If you wish to register for an event, either a physical or online
            event, you will need to provide your name, email address, and
            certain other information as appropriate for the event.
          </p>
        </div>

        <b>
          <p class="font-weight-bold">How we use personal information</p>
        </b>
        <p>
          In particular, we use the personal information we collect from you for
          the following purposes:
          <ul>
            <li>
              personalising the look and feel of the Site, to fit personal
              preferences which we have inferred from your usage of the Site and
              to provide you with the appropriate local version of the Site (see
              the "Analytics" and "Cookies" sections for more information);
            </li>
            <li>
              communicating with you regarding our products and services, in
              line with your stated preferences; and
            </li>
            <li>
              improving the Site and the service we offer - for example you may
              be asked to complete one of our online user satisfaction surveys.
            </li>
          </ul>
        </p>
        <p>
          We will only use your information where we have obtained appropriate
          permissions from you (where necessary) and will only use the
          information in accordance with this Policy, or where it is required
          and authorised by the applicable law.
        </p>
        <b>
          <p class="font-weight-bold">Communications from us</p>
        </b>
        <p>
          Where you have indicated you would like to receive newsletter(s) we
          may send these together with marketing in the form of email alerts and
          bulletins to tell you about our services.
        </p>
        <p>
          You can also unsubscribe from receiving certain electronic messages by
          following the “unsubscribe” instructions included in our
          communication.
        </p>
        <b>
          <p class="font-weight-bold">Providing information to others</p>
        </b>
        <p>
          We work closely with trusted partners with whom we need to share
          personal information to help us run this site and offer our products
          and services to you. Where appropriate, information may be provided to
          these partners in an aggregated form, such that it would not
          personally identify you.{" "}
        </p>
        <p>
          We may also share personal information with law enforcement bodies, in
          order to comply with any legal obligation or court order and, where we
          are otherwise requested to provide information, we may provide it but
          only in accordance with applicable privacy and data protection laws.
        </p>
        <p>
          We may also provide information to third party service providers who,
          as data processors, will only process information on our behalf and
          under our instructions to help run some of our internal business
          operations including email distribution, IT services and customer
          services, or to provide advertising on the Site.
        </p>
        <p>
          These recipients, both service providers and FieldAtWork partners may
          be located in the UK, in other countries in the European Economic Area
          or elsewhere in the world, such as for example Australia, that do not
          offer an equivalent level of protection of privacy as in the European
          Economic Area.
          <b>
            <span class="font-weight-bold">
              {" "}
              Different privacy laws may apply in these countries and you
              understand and unambiguously consent to the transfer of personal
              information to these countries, group companies and service
              providers.
            </span>
          </b>
        </p>
        <p>
          We only make these arrangements or transfers where we are satisfied
          that adequate levels of protection are in place to protect any
          information held in that country or that the service provider acts at
          all times in compliance with applicable privacy laws. Where required
          under applicable laws we will take measures to ensure that personal
          information handled in other countries will receive at least the same
          level of protection as it is given in your home country.
        </p>
        <p>
          In the event that your personal information is transferred to a
          foreign jurisdiction, it may be subject to the laws of that
          jurisdiction and we may be required to disclose it to the courts, law
          enforcement agencies or governmental authorities in those
          jurisdictions but we will only do so where required by applicable
          laws.
        </p>
        <p>
          We will only share information with such organisations where we have
          your permission to do so in accordance with this Policy, or it is
          mandatory under the corresponding applicable laws.
        </p>
        <p>
          You agree that we may disclose or share your personal information with
          such partners in order that we may operate the Site and offer our
          products and services to you.
        </p>
        <b>
          <p class="font-weight-bold">Keeping information secure</p>
        </b>
        <p>
          Security is very important for us. We apply the security measures
          foreseen in the applicable regulations as well as our own security
          measures.{" "}
        </p>
        <p>
          We invest significant resources to protect your personal information
          from loss, misuse, unauthorised access, modification or disclosure.
          However, no internet-based site can be 100% secure and we cannot be
          held responsible for unauthorised or unintended access that is beyond
          our control.
        </p>
        <b>
          <p class="font-weight-bold">Keeping your records</p>
        </b>
        <p>
          We keep records for as long as required to provide the relevant
          service, and in accordance with legal, tax and accounting
          requirements. Where your information is no longer required, we will
          ensure it is destroyed in a secure manner and, where required by
          applicable law we will notify you when such information has been
          destroyed.
        </p>
        <b>
          <p class="font-weight-bold">Cookies</p>
        </b>
        <p>
          This Site uses cookies (which includes third party cookies to support
          analytics functionality) and other similar technologies, to improve
          the user experience. We use temporary cookies to save access
          permissions for our visitors so that they do not have to log in
          multiple times as they attempt to access restricted pages during a
          single online session. These cookies expire with the current session,
          do not contain any personal information, and are not shared with any
          other web site. Should you not want any cookies from our web sites, we
          recommend you adjust your browser settings to provide the desired
          blocking.
        </p>
        <b>
          <p class="font-weight-bold">
            Access, rectification, cancellation and objection rights
          </p>
        </b>
        <p>
          In some jurisdictions you may have the right to request a copy of your
          personal information which is within our custody and control together
          with details about how we use that information. If you think any of
          the personal information we hold about you is inaccurate, you may also
          request it is corrected. You may also have a right to request erasure
          of your personal information, as well as, in certain circumstances, to
          stop or challenge the processing of your personal information.
        </p>
        <p>
          In relation to all of these rights, please email or write to us at the
          address below. This request must include at least the following
          information: your complete name, notification address, copy of your ID
          card or passport and explanation of the right that it is exercised. We
          will respond to your requests within all applicable timeframes.
        </p>
        <b>
          <p class="font-weight-bold">Complaints Process</p>
        </b>
        <p>
          If you have a complaint about how we have handled your personal
          information you may contact us using the details below and we will
          investigate your complaint.
        </p>
        <b>
          <p class="font-weight-bold">Third party sites</p>
        </b>
        <p>
          This Policy only applies to this Site. If you land on our Site from
          other websites (or move to other websites from our Site) you should
          read their separate privacy policies.
        </p>
        <b>
          <p class="font-weight-bold">Terms of Use</p>
        </b>
        <p>
          This Privacy Policy governs the ways in which we collect and use
          information about you through the Site. For the terms and conditions
          which apply to your use of this Site.
        </p>
        <b>
          <p class="font-weight-bold">Updates</p>
        </b>
        <p>
          We keep this Policy under regular review and update it from time to
          time. Please review this policy periodically for changes.
        </p>
        <p>
          If you do not accept the amended Policy, please stop using this Site.{" "}
        </p>
        <b>
          <p class="font-weight-bold">Contact</p>
        </b>
        <p>
          If you have any questions about this Policy, or would like to exercise
          your rights with respect to your personal information, please contact
          us via info@fieldatwork.com or write to Suite 1301, 9 Yarra Street,
          South Yarra, VIC 3141, AUSTRALIA.{" "}
        </p>
        <p>Date: December 19, 2018</p>
      </Wrapper>
    );
  }
}

export default Privacy;
