import React, { Component } from "react";
import glamorous from "glamorous";
import { ColourPalette, FontSize, Transition } from "../styles/Globals";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

const Wrapper = glamorous.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flex: "1 1 auto",
  justifyContent: "space-between",
  padding: "0px calc(5%)",
  alignItems: "center",
  backgroundColor: ColourPalette.FawBlue
});

const FooterText = glamorous.div({
  color: ColourPalette.FawYellow,
  fontSize: FontSize.SubHeader
});

const FooterItem = glamorous(FooterText)({
  transition: Transition.Normal,
  ":hover": {
    cursor: "pointer",
    color: "#ffffff"
  }
});

const LinkWrapper = glamorous.div({
  display: "flex"
});

class Footer extends Component {
  onClick(loc) {
    window.location = "/" + loc;
  }

  render() {
    return (
      <Wrapper className="animate-gradient">
        <FooterText>FieldAtWork Pty Ltd 2018</FooterText>

        <Router>
          <LinkWrapper>
            <FooterItem onClick={() => this.onClick("tos")}>
              Terms of Service
            </FooterItem>
            <FooterText style={{ color: "#fff" }}>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </FooterText>
            <FooterItem onClick={() => this.onClick("privacy")}>
              Privacy Statement
            </FooterItem>
          </LinkWrapper>
        </Router>
      </Wrapper>
    );
  }
}

export default Footer;
