import glamorous from "glamorous";

export const HEADER_MAX_HEIGHT = 130;
export const HEADER_MIN_HEIGHT = 100;

export const Transition = {
  Normal: "0.4s",
  NormalLong: "0.65s"
};

export const ColourPalette = {
  FawBlue: "#1e49b5",
  FawBlueLight: "#3164e2",
  TextRegular: "#383838",
  TextLight: "#eeeeee",
  FawYellow: "#f7c851",
  FawYellowLight: "#fcf2bf"
};

export const FontSize = {
  Header: "40px",
  Header2: "32px",
  SubHeader: "24px",
  Regular: "20px"
};

const PADDING = 100;
export const InfoBlockWrapper = glamorous.div({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "calc(100% - " + (HEADER_MAX_HEIGHT + 2 * PADDING) + "px)",
  // width: "calc(100% - " + 2 * PADDING + "px)",
  // maxWidth: "1200px",
  // backgroundColor: "#123456",
  padding: HEADER_MAX_HEIGHT + 100 + "px 100px 100px 100px"
});

export default {
  HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT,
  Transition,
  ColourPalette,
  FontSize,
  InfoBlockWrapper
};
