import React, { Component } from "react";
import glamorous from "glamorous";
import { FontSize, ColourPalette } from "./../../styles/Globals";

const Wrapper = glamorous.div(
  {
    display: "flex",
    flex: "1 1 auto",
    height: "100%",
    borderRadius: "10px"
  },
  props => ({
    flexDirection: props.reverse ? "row-reverse" : "row"
    // background: props.dark
    //   ? ColourPalette.FawBlue + "77"
    //   : ColourPalette.FawYellow + "77"
  })
);

const Text = glamorous.div(
  {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    padding: "0px 40px",
    maxWidth: "580px"
  },
  props => ({
    textAlign: props.reverse ? "right" : "left"
  })
);

const Header = glamorous.div(
  {
    fontSize: FontSize.Header
  },
  props => ({
    color: props.dark ? ColourPalette.FawYellow : ColourPalette.FawBlue
  })
);

const Description = glamorous.div(
  {
    fontSize: FontSize.SubHeader
  },
  props => ({
    color: props.dark ? ColourPalette.TextLight : ColourPalette.TextRegular
  })
);
class InfoSingle extends Component {
  render() {
    console.log(this.props);
    const { info, reverse, dark } = this.props;
    console.log(dark);
    return (
      <Wrapper reverse={reverse} dark={dark} className="breakdown">
        <img
          src={info.image}
          alt={info.header}
          style={{ height: "100%", display: "flex" }}
        />
        <Text reverse={reverse}>
          <Header dark={dark}>{info.header}</Header>
          <Description dark={dark}>{info.desc}</Description>
        </Text>
      </Wrapper>
    );
  }
}

export default InfoSingle;
