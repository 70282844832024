import React, { Component } from "react";

import "./App.css";
import "./styles/Footer.css";

import Header from "./components/Header";
import glamorous from "glamorous";
import Breakdown from "./components/Breakdown";

import Banner from "./components/Banner";
import Bottom from "./components/Bottom";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Privacy from "./components/Privacy";
import Tos from "./components/Tos";
import Contact from "./components/Contact";

const Image = require("./assets/placeholder.jpg");

const SectionContainer = glamorous.div(
  {
    scrollSnapAlign: "start"
  },
  props => ({ height: props.height })
);

const AppWrapper = glamorous.div({
  scrollSnapType: "y mandatory",
  maxHeight: "100vh",
  overflowY: "auto",
  fontFamily: "Quicksand, sans-serif",
  display: "flex",
  flexDirection: "column"
});

const Info = [
  {
    image: Image,
    header: "The Concept",
    desc:
      "If you have an idea for a business application that can be delivered from the cloud."
  },
  {
    image: Image,
    header: "The Need",
    desc: "If there is a quantifiable need."
  },
  {
    image: Image,
    header: "The Development",
    desc:
      "If you want to drive your idea within a proven cloud based framework."
  },
  {
    image: Image,
    header: "The Market",
    desc: "If you want to get to market in the most resource effective manner."
  }
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (
      window.innerHeight !== this.state.height &&
      window.innerWidth !== this.state.width
    ) {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  }
  render() {
    return (
      <AppWrapper>
        <Header />
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <div>
                    <SectionContainer height={this.state.height}>
                      <Banner />
                    </SectionContainer>

                    <SectionContainer height={this.state.height}>
                      <Breakdown Info0={Info[0]} Info1={Info[1]} />
                    </SectionContainer>
                    <SectionContainer height={this.state.height}>
                      <Breakdown Info0={Info[2]} Info1={Info[3]} dark={true} />
                    </SectionContainer>
                    <SectionContainer height={this.state.height}>
                      <Bottom />
                    </SectionContainer>
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/privacy"
              render={() => {
                return <Privacy />;
              }}
            />

            <Route
              exact
              path="/tos"
              render={() => {
                return <Tos />;
              }}
            />
            <Route
              exact
              path="/contact"
              render={() => {
                return <Contact />;
              }}
            />
          </Switch>
        </BrowserRouter>
      </AppWrapper>
    );
  }
}

export default App;
