import React, { Component } from "react";
import {
  InfoBlockWrapper,
  HEADER_MAX_HEIGHT,
  ColourPalette
} from "../styles/Globals";
import glamorous from "glamorous";
import Footer from "./Footer";
import Textplanation from "./Textplanation";

const Wrapper = glamorous.div({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "calc(100% - " + HEADER_MAX_HEIGHT + "px)", // - " + (HEADER_MAX_HEIGHT + 2 * PADDING) + "px)",
  // width: "calc(100% - " + 2 * PADDING + "px)",
  // maxWidth: "1200px",
  // backgroundColor: "#123456",
  paddingTop: HEADER_MAX_HEIGHT, // + 100 + "px 100px 100px 100px",
  backgroundColor: "#fffef4"
  // backgroundImage: "url(" + BackgroundSvg + ")",
  // backgroundRepeat: "cover"
});

const TextplanationWrapper = glamorous.div({
  height: "90%",
  width: "100%",
  display: "flex"
});

const FooterWrapper = glamorous.div({
  height: "10%",
  width: "100%",
  display: "flex",
  flex: "1 1 auto"
});

class Bottom extends Component {
  render() {
    return (
      <Wrapper>
        <TextplanationWrapper>
          <Textplanation />
        </TextplanationWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Wrapper>
    );
  }
}

export default Bottom;
