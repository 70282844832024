import React, { Component } from "react";
import { InfoBlockWrapper, ColourPalette } from "../styles/Globals";
import glamorous from "glamorous";
import InfoSingle from "./Organisms/InfoSingle";
import "./../styles/Breakdown.css";

const SingleWrapper = glamorous.div({ height: "45%" });

const Yellow = require("./../assets/yellow3.svg");
const Blue = require("./../assets/blue3.svg");

class Breakdown1 extends Component {
  render() {
    const { Info0, Info1, dark } = this.props;
    return (
      <div
        className={dark ? "animate-gradient" : "animate-gradient-yellow"}
        style={{
          display: "flex",
          width: "100%",
          height: "100%"
        }}
      >
        <InfoBlockWrapper
          style={{
            backgroundImage: dark ? "url(" + Blue + ")" : "url(" + Yellow + ")",
            backgroundSize: "cover"
          }}
        >
          {/* <SingleWrapper className="breakdown"> */}
          <SingleWrapper>
            <InfoSingle info={Info0} reverse={true} dark={dark} />
          </SingleWrapper>
          <SingleWrapper>
            <InfoSingle info={Info1} reverse={false} dark={dark} />
          </SingleWrapper>
        </InfoBlockWrapper>
      </div>
    );
  }
}

export default Breakdown1;
