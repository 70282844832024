import React, { Component } from "react";
import glamorous from "glamorous";
import {
  HEADER_MAX_HEIGHT,
  ColourPalette,
  Transition
} from "./../styles/Globals";

const Logo = require("./../assets/fieldatwork_logo_white.png");

const HeaderWrapper = glamorous.div(
  {
    display: "flex",
    alignItems: "center",
    flex: "1 1 auto",
    width: "100%",
    position: "fixed",
    top: "0px",
    justifyContent: "center",
    zIndex: "1000",
    // backgroundColor: ColourPalette.FawBlue,
    fillOpacity: "0"
    // verticalAlign: "middle"
  },
  props => ({
    height: props.height
  })
);

// const Title = glamorous.div({
//   textAlign: "center",
//   fontSize: "36px",
//   marginLeft: "20px",
//   color: "#ffffff",
//   marginRight: "20px",
//   ":hover": {
//     // fillOpacity: "50%"
//     cursor: "pointer"
//   }
// });

const TitleLogo = glamorous.div({
  height: (HEADER_MAX_HEIGHT * 2) / 3 + "px",
  transition: Transition.Normal,
  ":hover": {
    cursor: "pointer",
    transform: "scale(1.2)"
    // background: ColourPalette.FawYellow
  }
});

// const MenuItem = glamorous.div({
//   textAlign: "center",
//   fontSize: "20px",
//   marginLeft: "15px",
//   marginRight: "15px",
//   borderBottom: "dotted 1px transparent",
//   transition: Transition.Normal,
//   ":hover": {
//     // fillOpacity: "50%"
//     cursor: "pointer",
//     // backgroundColor: "#000000"
//     borderBottom: "solid 1px black"
//   }
// });

class Header extends Component {
  onClick() {
    console.log("gjdangjab;j");
    // this.props.history.push("/");
    window.location = "/";
  }
  render() {
    const title = (
      <TitleLogo onClick={this.onClick}>
        <img src={Logo} alt="FieldAtWork" style={{ height: "100%" }} />
      </TitleLogo>
    );
    // scrollHeight < HEADER_MAX_HEIGHT ? (
    //   <Title>FieldAtWork</Title>
    // ) : (
    //   <Title>FAW</Title>
    // );

    return (
      <HeaderWrapper height={HEADER_MAX_HEIGHT} className="animate-gradient">
        {/* <MenuItem>Blog</MenuItem>
        <MenuItem>Events</MenuItem> */}
        {title}
        {/* <MenuItem>About Us</MenuItem>
        <MenuItem>Contact</MenuItem> */}
      </HeaderWrapper>
    );
  }
}

export default Header;
