import React, { Component } from "react";
import glamorous from "glamorous";
import "./../styles/Contact.css";
import "./../styles/Banner.css";

import { HEADER_MAX_HEIGHT, ColourPalette, FontSize } from "../styles/Globals";

const Wrapper = glamorous.div({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  zIndex: -1
});
const CloudWrapper = glamorous.div({
  paddingTop: "40px",
  zIndex: "-1",
  overflow: "hidden",
  padding: HEADER_MAX_HEIGHT + 40 + "px calc(15%) calc(5%) calc(15%)",
  height: "60%",
  position: "relative"
});

const TextWrapper = glamorous.div({
  position: "absolute",
  top: "80%",
  left: "50%",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  color: ColourPalette.FawYellow,
  fontSize: FontSize.Header2,
  fontWeight: "600"
  //   paddingBottom: "40px"
});

class Contact extends Component {
  render() {
    return (
      <Wrapper className="animate-gradient">
        <CloudWrapper>
          <div class="x1">
            <div class="cloud" />
          </div>

          <div class="x2">
            <div class="cloud" />
          </div>

          <div class="x3">
            <div class="cloud" />
          </div>

          <div class="x4">
            <div class="cloud" />
          </div>

          <div class="x5">
            <div class="cloud" />
          </div>
          <div class="balloon">
            <div class="envelope" />
            <div class="basket" />
          </div>
        </CloudWrapper>
        <TextWrapper className="text">
          Email: enquiries@fieldatwork.com
          <br />
          <br />
          Phone: +61 9008 5830
        </TextWrapper>
      </Wrapper>
    );
  }
}

export default Contact;
