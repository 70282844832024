import React, { Component } from "react";
import glamorous from "glamorous";
import { HEADER_MAX_HEIGHT, FontSize } from "../styles/Globals";

const Wrapper = glamorous.div({
  padding: HEADER_MAX_HEIGHT + 40 + "px calc(15%) calc(5%) calc(15%)",
  fontSize: FontSize.Regular
});
class Tos extends Component {
  render() {
    return (
      <Wrapper>
        <p>
          These Terms of Use, and any documents referred to below, set out the
          terms and conditions on which you are permitted to use our Site,
          www.fieldatwork.com (‘our Site’). The Site is owned and operated by
          FieldAtWork Pty Ltd (we, our and us). By using our Site, you agree to
          be bound by, and to comply with, these Terms of Use.
        </p>
        <b>
          <p class="font-weight-bold">
            Please read these Terms of Use carefully.
          </p>
        </b>
        <p>
          If for any reason whatsoever you do not agree to these Terms of Use or
          do not wish to be bound by them, you must not access or use our Site.
        </p>
        <b>
          <p class="font-weight-bold">Your access to our Site</p>
        </b>
        <p>
          You must ensure that any persons who accesses our Site on your
          computer(s) and/or other device(s), are aware of these Terms of Use
          and all other documentation referred to in them, and that such persons
          also agree to be bound by and to comply with these Terms of Use. If
          for any reason whatsoever, such persons do not agree to these Terms of
          Use or do not wish to be bound by them, they must not access or use
          our Site.
        </p>
        <b>
          <p class="font-weight-bold">
            Other documents (‘other documents’) governing your use of our Site
          </p>
        </b>
        <p>
          In addition to these Terms of Use, the use of our Site is also
          governed by the following documents:
        </p>
        <ol>
          <li>
            Our Privacy Policy, which governs our use of your personal
            information and includes our cookies policy.
          </li>
        </ol>
        <b>
          <p class="font-weight-bold">Availability of our Site</p>
        </b>
        <p>We make no representations and provide no warranties that:</p>
        <ol>
          <li>
            the Site will be made available at any specific time or from any
            specific geographical location;
          </li>
          <li>
            your access to the Site will be continuous or uninterrupted; or
          </li>
          <li>
            the Site will be accessible or optimised on all browsers, computers,
            tablets, phones or other viewing platforms.
          </li>
        </ol>
        <p>
          We reserve the right to suspend access to all or part of the Site for
          any reason, including for business or operational reasons.
        </p>
        <b>
          <p class="font-weight-bold">
            Changes we may make to these Terms of Use and other documents
          </p>
        </b>
        <p>
          We reserve the right to update these Terms of Use and other documents.
          In particular, we may update documentation to ensure that it remains
          compliant with any and all current and future applicable laws,
          regulations and official guidance.
        </p>
        <p>
          If required by law, we will provide you with notice of any changes in
          these Terms of Use or the other documents by posting a notice on the
          Site and/or by posting an updated version of these Terms of Use or
          other documents on our Site with a new effective date.
        </p>
        <p>
          By continuing to access our Site after we have updated our Terms of
          Use and/or other documents, you agree to be bound by that updated
          version. You must check these Terms of Use and all other documentation
          referred to in them each time you access our Site in order to ensure
          that you are aware of the terms that apply to you at that time.
        </p>
        <p>
          The effective date for these Terms of Use and or other documents is
          set out at the bottom of that document.
        </p>
        <b>
          <p class="font-weight-bold">Your account details</p>
        </b>
        <p>
          If we provide you with account information such as a user name,
          identification number, account code and/or password, you must keep
          such information confidential and secret and not disclose it to
          anyone. All account information is provided for use of the named
          account holder only, and not for any other person. You are responsible
          for any consequences of unauthorised access to your account due to any
          disclosure of your account information to any third party.
        </p>
        <p>
          Where we provide you with the option to select your own login
          information, including a password, we recommend that you supply login
          information unique to your own use of this Site, and do not use
          information from other accounts you may hold with other Sites or any
          easily discoverable personal information. You are responsible for any
          consequences of unauthorised access to your account due to any
          disclosure of your login information to any third party.
        </p>
        <p>
          You must never use another user’s account without permission. When
          creating your account, you must provide accurate and complete
          information. You agree that you will not solicit, collect or use the
          login credentials of other individuals. We prohibit the creation of,
          and you agree that you will not create, an account for anyone other
          than yourself. You also represent that all information you provide to
          us upon registration and at all other times will be true, accurate,
          current, and complete. You agree to update your information as
          necessary to maintain its truth and accuracy.
        </p>
        <p>
          We reserve the right to withdraw access to your account without notice
          for any actual or suspected breach of these Terms of Use or any other
          documentation referred to in them, including, without limitation,
          where we suspect that there has been unauthorised access to your
          account, or any unauthorised disclosure of your login information.
        </p>
        <p>
          If you know or suspect that the confidentiality of your login
          information has been compromised, for example, by the disclosure of
          such information to any third party, you must immediately change your
          password. If you are unable to change your password, you must
          immediately notify us by email, at info@fieldatwork.com
        </p>
        <b>
          <p class="font-weight-bold">Ownership of material on our Site</p>
        </b>
        <p>
          All trade marks, service marks, trade names, logos, copyright and
          other intellectual property rights in our Site and its content are
          either owned by us or licensed to us. All such rights are protected by
          intellectual property laws around the world and all rights are
          reserved. Any use of the Site and its contents, other than as
          specifically authorised herein, is strictly prohibited. Any rights not
          expressly granted herein are reserved by us.
        </p>
        <p>
          Trade marks, service marks, trade names, logos and other branding
          owned by third parties and used or displayed on or via our Site
          (collectively, “Third Party Mark(s)”) may be trade marks of their
          respective owners who may or may not endorse or be affiliated with or
          connected with us. Except as expressly provided in these Terms of Use,
          or in terms provided by the owner of a Third Party Mark, nothing in
          these Terms of Use or on or via the Site should be construed as
          granting, by implication, estoppel, or otherwise, any licence or right
          to use any of our or any Third Party Marks that are used or displayed
          on the Site, without the respective owner’s prior written permission,
          in each instance. All goodwill generated from the use of our trade
          marks will benefit us exclusively.
        </p>
        <b>
          <p class="font-weight-bold">
            Information and content on our Site provided on non-reliance basis
          </p>
        </b>
        <p>
          Our Site is made available to you in order to provide you with general
          information about us, our business, and any products or services that
          we offer from time to time. We do not make our Site available for any
          other purposes, except as expressly provided in these Terms of Use.
        </p>
        <p>
          The content on our Site is not intended to be construed as advice. You
          must not rely on any of the content of our Site for any purposes
          whatsoever and you must seek your own independent professional advice
          before deciding to take any course of action on the basis, whether in
          whole or in part, of any of the content available on our Site from
          time to time.
        </p>
        <p>
          We make no representations and provide no warranties whatsoever,
          whether express or implied, that any of the content or materials
          available on our Site are accurate, up-to-date or complete.
        </p>
        <p>
          Where we display discounts, time limits or any other special terms
          applicable to the products on our Site from time to time, these shall
          constitute invitations to treat, not contractual offers and, where
          time limits or other special terms apply, such terms shall only
          represent our intention to apply such terms at that point in time and
          we reserve the right to amend, revise, cancel, extend or take any
          other action in relation to such discounts, time limits and special
          terms at any point and for any reason we deem appropriate, in our sole
          discretion.
        </p>
        <p>
          Nothing in these Terms of Use shall act to exclude or limit any of
          your statutory or legal rights to the extent that they cannot be
          excluded or limited by these Terms of Use.
        </p>
        <b>
          <p class="font-weight-bold">Permitted use of materials on our Site</p>
        </b>
        <p>
          The content on our Site is provided for your personal, private and
          non-commercial use only. You may print or share the content from our
          Site for lawful personal purposes, private or non-commercial purposes,
          and you may also make others within your organisation aware of the
          content on our Site. You may not otherwise extract, reproduce or
          distribute the content of our Site without our prior written consent.
        </p>
        <p>
          Whenever you print, download, share or pass on content from our Site
          to others, you must not make any additions or deletions or otherwise
          modify any text from our Site, you must not alter or change in any way
          any images, media or graphics from our Site in any way, you may not
          remove any accompanying text from such images, media or graphics, and
          you must ensure that all content passed on to any third party is an
          accurate representation of the content as it appears on our Site.
        </p>
        <p>
          You are prohibited from using any robots, spiders, data mining or
          scraping technology or any similar third-party tools for the
          extraction or reproduction of any data or content from our Site
          without our prior written consent.
        </p>
        <p>
          Whenever you pass on any content or materials from our Site to anyone,
          you must acknowledge us as the authors of such content or materials
          (or any other authors wherever credited by us) at the time when you
          pass on such content or materials.
        </p>
        <b>
          <p class="font-weight-bold">Prohibited uses of our Site</p>
        </b>
        <p>
          You must not reproduce, duplicate, copy or resell any part of our Site
          or any content from or of our Site, save and except to the extent
          expressly permitted in these Terms of Use.
        </p>
        <p>
          You must not, without our prior written consent, access, interfere
          with, damage or disrupt in any way our Site or any part of it, our
          systems, any of our hardware or equipment or any networks on which our
          Site is hosted, any software that we use to create or modify the Site
          or to make the Site available to you, or any hardware, equipment,
          network, server, software or technology owned or operated by us or any
          third party.
        </p>
        <p>
          You must use our Site for lawful purposes only and in accordance with
          these Terms of Use. You must not use our Site:
        </p>
        <ol>
          <li>
            for any purpose that is unlawful or that in any way breaches any
            applicable laws or regulations, whether local, national or
            international;
          </li>
          <li>for any fraudulent purposes whatsoever;</li>
          <li>
            to conduct any unsolicited or unauthorised advertising or direct or
            indirect marketing to anyone by any means, or to otherwise spam,
            communicate with or market to anyone any goods, services or business
            not authorised by us;
          </li>
          <li>
            to upload, host or transmit any viruses, malware, adware, spyware,
            worms, Trojan horses, keystroke loggers, spyware, logic bombs, time
            bombs or any other harmful programs or code which could adversely
            affect the use or operation of the Site, our hardware or systems, or
            the computers, tablets, phones or other devices of any users or
            other third parties, or to upload any content or materials
            containing any such content;
          </li>
          <li>
            to communicate with, harm or attempt to harm children in any way; or
          </li>
          <li>
            in any way or for any purpose that breaches these Terms of Use or
            the terms of any of the documents these Terms of Use refer to.
          </li>
          <li>
            You must not submit to us any personal information about you if you
            are under the age of 18 or about any other person who is either
            under the age of 18; or if they are aged 18 or above, where you have
            not received their prior written consent to submit personal
            information about them to us.
          </li>
        </ol>
        <b>
          <p class="font-weight-bold">Viruses and other harmful content</p>
        </b>
        <ol>
          <li>
            We do not guarantee that our Site does not contain viruses or other
            malicious software.
          </li>
          <li>
            We shall not be responsible for any bugs or viruses on our Site, any
            such software that might be transferred to your computer from our
            Site, or any consequences which the presence or operation of such
            programs may have.
          </li>
          <li>
            You must ensure that you have in place up-to-date and effective
            anti-virus protection on your computer or other browsing device.
          </li>
          <li>
            You must not upload or otherwise introduce to our Site any viruses,
            malware, spyware, adware, Trojan horses, worms, logic bombs, time
            bombs, keystroke loggers or any other programs or code that is
            harmful or malicious.
          </li>
          <li>
            You must not use any third parties, software or technology to
            attempt to gain unauthorised access to our Site, our servers,
            systems, hardware, software or data.
          </li>
          <li>
            You must not attempt to perform any denial of service type attack on
            our Site.
          </li>
          <li>
            You must not perform any action which would contravene the Computer
            Misuse Act 1990.
          </li>
          <li>
            We may report any breach or suspected breach of this clause 11
            (Viruses and other harmful content) to the relevant authorities and
            may disclose your identity.
          </li>
        </ol>
        <b>
          <p class="font-weight-bold">Links to other Sites</p>
        </b>
        <p>
          Links to third party content or Sites may appear on our Site from time
          to time. We are not responsible for the content of any Sites
          accessible via any link(s) on our Site. All content on third party
          Sites is outside of our control and we do not represent or warrant
          that such content is related to us or our Site, suitable or
          appropriate for use or viewing, lawful or accurate.
        </p>
        <p>
          Any third party Site accessible via a link on our Site may collect and
          process your personal information. We are not responsible for any
          data-processing activities carried out by any third party Site which
          is linked to from our Site. You should check the privacy policy of any
          such third party to establish how they may use your personal
          information before you decide to use their Site and its features.
        </p>
        <b>
          <p class="font-weight-bold">Links to our Site</p>
        </b>
        <p>
          You may not link to our Site without our prior consent. Where you have
          obtained our consent to link to our Site:
        </p>
        <ol>
          <li>
            you may provide links to our Site on other Sites owned by you,
            provided that such Sites and the use of any links to our Site comply
            with these Terms of Use;
          </li>
          <li>
            wherever you post a link to our Site on any other Site, you agree
            that you will do so in an appropriate manner, and not in any way
            which is defamatory or disparaging towards us, which misrepresents
            us or our business, or which causes any harm whatsoever to us or our
            business; and
          </li>
          <li>
            you must not link to our Site in order to suggest any form of joint
            venture, partnership, collaboration, affiliation, business
            relationship, approval or endorsement in connection with us where
            none exists and, in any event, without having first obtained our
            prior written consent.
          </li>
        </ol>
        <p>
          We may withdraw permission to link to our Site at any time. In the
          event that we withdraw permission to link to our Site and inform you
          of the same, you must immediately remove or cause to be removed any
          links to our Site.
        </p>
        <b>
          <p class="font-weight-bold">
            Exclusions and Limitations of Liability
          </p>
        </b>
        <p>
          We do not exclude our liability to you where it would be unlawful to
          do so. If applicable law does not allow all or any part of the below
          limitations of liability to apply to you, the limitations will apply
          to you only to the maximum extent permitted by applicable law.
        </p>
        <p>
          If you purchase goods or services from our Site, different exclusions
          of liability may apply. These are contained in our terms of business.
        </p>
        <p>
          IN NO EVENT SHALL WE (INCLUDING OUR OFFICERS, DIRECTORS, MEMBERS,
          EMPLOYEES OR AGENTS, UNDER ANY CIRCUMSTANCES WHATSOEVER BE LIABLE TO
          YOU FOR ANY LOSS, DAMAGE (WHETHER DIRECT, INDIRECT, PUNITIVE, ACTUAL,
          CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR OTHERWISE) COSTS,
          EXPENSES, LIABILITIES OR PENALTIES, WHETHER IN CONTRACT, TORT, BREACH
          OF STATUTORY DUTY OR OTHERWISE, WHETHER FORESEEABLE OR UNKNOWN,
          ARISING FROM, IN CONNECTION WITH OR RELATING TO:
        </p>
        <ol>
          <li>YOUR USE OF OUR SITE;</li>
          <li>ANY CORRUPTION OR LOSS OF DATA;</li>
          <li>
            ANY INABILITY TO ACCESS OUR SITE, INCLUDING, WITHOUT LIMITATION, ANY
            INTERRUPTIONS, SUSPENSION OR WITHDRAWAL OF OUR SITE (FOR ANY REASON
            WHATSOEVER);
          </li>
          <li>
            ANY USE YOU MAKE OF ANY CONTENT OR MATERIALS ON OUR SITE, INCLUDING
            ANY RELIANCE YOU MAKE ON SUCH CONTENT OR MATERIAL;
          </li>
          <li>ANY LOSS OF SAVINGS, PROFITS, SALES, BUSINESS OR REVENUE;</li>
          <li>ANY LOSS OF REPUTATION OR GOODWILL;</li>
          <li>ANY LOSS OF SAVINGS;</li>
          <li>ANY LOSS OF A CHANCE OR OPPORTUNITY; OR</li>
          <li>ANY OTHER SECONDARY, CONSEQUENTIAL OR INDIRECT LOSSES.</li>
        </ol>
        <p>
          AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR
          DAMAGE, WITHOUT LIMITATION, YOU ASSUME THE ENTIRE COST OF ALL
          NECESSARY SERVICING, REPAIR, OR CORRECTION IN THE EVENT OF ANY SUCH
          LOSS, DAMAGE, COSTS, EXPENSES, LIABILITIES OR PENALTIES ARISING.
        </p>
        <p>
          WE SHALL NOT BE LIABLE FOR ANY DAMAGE THAT YOU COULD HAVE AVOIDED BY
          FOLLOWING OUR ADVICE TO APPLY AN UPDATE OFFERED TO YOU FREE OF CHARGE
          OR FOR DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY FOLLOW
          INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM
          REQUIREMENTS ADVISED BY US.
        </p>
        <p>
          You specifically agree that we shall not be liable for any content or
          the defamatory, offensive, or illegal conduct of any third party and
          that the risk of harm or damage from the foregoing rests entirely with
          you.
        </p>
        <p>
          YOU AGREE THAT IN THE EVENT THAT YOU INCUR ANY DAMAGES, LOSSES OR
          INJURIES ARISING OUT OF, OR IN CONNECTION WITH, OUR ACTS OR OMISSIONS,
          THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT
          TO ENTITLE YOU TO AN INJUNCTION PREVENTING ANY EXPLOITATION OF ANY
          SITE, SERVICE, PROPERTY, PRODUCT OR OTHER CONTENT OWNED OR CONTROLLED
          BY US, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN THE
          DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR
          EXPLOITATION OF ANY SITE, PROPERTY, PRODUCT, SERVICE, OR OTHER CONTENT
          OWNED OR CONTROLLED BY US.
        </p>
        <p>
          To the extent that any of the above Exclusions and Limitations of
          Liability are unenforceable as outright exclusions of liability, they
          shall be construed as limitations on liability, limiting our liability
          to you to the maximum extent permitted by law.
        </p>
        <b>
          <p class="font-weight-bold">Indemnification</p>
        </b>
        <p>
          You (and also any third party for or on behalf of whom you operate an
          account or activity on the Site) agree to defend (at our request),
          indemnify and hold us harmless from and against any claims,
          liabilities, damages, losses, and expenses, including without
          limitation, reasonable legal and attorney’s fees and costs, arising
          out of or in any way connected with any of the following (including as
          a result of your direct activities on the Site or those conducted on
          your behalf):
        </p>
        <ol>
          <li>your uploads, access to or use of the Site;</li>
          <li>your breach or alleged breach of these Terms of Use;</li>
          <li>
            your violation of any third-party right, including, without
            limitation, any intellectual property right, publicity,
            confidentiality, property or privacy right;
          </li>
          <li>
            your violation of any laws, rules, regulations, codes, statutes,
            ordinances or orders of any governmental and quasi-governmental
            authorities, including, without limitation, all regulatory,
            administrative and legislative authorities; or
          </li>
          <li>any misrepresentation made by you.</li>
        </ol>
        <p>
          You will cooperate as fully required by us in the defence of any
          claim. We reserve the right to assume the exclusive defence and
          control of any matter subject to indemnification by you, and you will
          not, in any event, settle any claim without our prior written consent.
        </p>
        <b>
          <p class="font-weight-bold">Disclaimers</p>
        </b>
        <p>
          THE SITE IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL
          FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE DO NOT
          MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND
          WHATSOEVER, EXPRESS OR IMPLIED, AS TO THE SERVICE; THE SITE CONTENT;
          USER CONTENT; OR SECURITY ASSOCIATED WITH THE TRANSMISSION OF
          INFORMATION TO THE SITE.
        </p>
        <p>
          IN ADDITION, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM,
          TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER
          VIRUS.
        </p>
        <p>
          WE DO NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE ERROR-FREE OR
          UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE SERVICE OR
          THE SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE FROM ANY HARMFUL
          COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. WE DO NOT MAKE ANY
          REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY
          INSTRUCTIONS) ON THE SERVICE IS ACCURATE, COMPLETE, OR USEFUL. YOU
          ACKNOWLEDGE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. WE DO NOT
          WARRANT THAT YOUR USE OF THE SITE IS LAWFUL IN ANY PARTICULAR
          JURISDICTION, AND WE SPECIFICALLY DISCLAIM SUCH WARRANTIES. SOME
          JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER
          WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT
          SUCH JURISDICTION’S LAW IS APPLICABLE TO YOU AND THESE TERMS OF USE.
        </p>
        <p>
          BY ACCESSING OR USING THE SITE YOU REPRESENT AND WARRANT THAT YOUR
          ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE
          THE SERVICE.
        </p>
        <p>
          WE DO NOT ENDORSE CONTENT AND SPECIFICALLY DISCLAIM ANY RESPONSIBILITY
          OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER
          ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM,
          LIABILITY OR OTHER CAUSE OF ACTION OF ANY KIND OR CHARACTER BASED UPON
          OR RESULTING FROM ANY CONTENT.
        </p>
        <b>
          <p class="font-weight-bold">Governing law and jurisdiction</p>
        </b>
        <p>
          These Terms of Use and other documents and any disputes arising from
          or in relation to them, whether contractual or non-contractual, shall
          be governed by and construed in accordance with Victorian law.
        </p>
        <p>
          The courts of Victoria and Australia shall have exclusive jurisdiction
          over any claims or disputes arising from or in relation to these Terms
          of Use and any documents they refer to.
        </p>
        <b>
          <p class="font-weight-bold">Contact Details</p>
        </b>
        <p>
          Please contact us via info@fieldatwork.com or write to Suite 1301, 9
          Yarra Street, South Yarra, VIC 3141, Australia
        </p>
        <p>Date: December 19, 2018</p>
      </Wrapper>
    );
  }
}

export default Tos;
