import React, { Component } from "react";
import glamorous, { Small } from "glamorous";
import { FontSize } from "../styles/Globals";

const Wrapper = glamorous.div({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "row",
  padding: "calc(5%) calc(12.5%)"
});

const BigExplanation = glamorous.div({
  height: "100%",
  width: "60%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const BigHeader = glamorous.div({
  fontSize: FontSize.Header,
  marginBottom: "calc(10%)"
});

const BigSubheader = glamorous.div({
  fontSize: FontSize.SubHeader
});

const SmallExplanation = glamorous.div({
  height: "100%",
  width: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  paddingLeft: "50px",
  fontSize: FontSize.Regular
});

class Textplanation extends Component {
  render() {
    return (
      <Wrapper>
        <BigExplanation>
          <BigHeader>Build a sustainable cloud enterprise</BigHeader>
          <BigSubheader>
            Our framework enables innovators to transform their idea into a
            sustainable cloud enterprise
          </BigSubheader>
        </BigExplanation>
        <SmallExplanation>
          <li>Transform how you think about cloud applications</li>
          <li>Achieve previously impossible outcomes</li>
          <li>Leave the outdated, traditional software lifecycle behind</li>
          <li>MORE WORDS HERE</li>
          <li>MORE WORDS HERE</li>
          <li>MORE WORDS HERE</li>
        </SmallExplanation>
      </Wrapper>
    );
  }
}

export default Textplanation;
