import React, { Component } from "react";
import glamorous from "glamorous";
import {
  ColourPalette,
  HEADER_MAX_HEIGHT,
  FontSize,
  Transition
} from "../styles/Globals";
import "./../styles/Banner.css";

const BannerWrapper = glamorous.div({
  //   backgroundColor: ColourPalette.FawBlue,
  overflow: "hidden",
  height: "calc(100% - " + HEADER_MAX_HEIGHT + "px)",
  paddingTop: HEADER_MAX_HEIGHT,
  position: "relative"
  // background:
  //   "linear-gradient(" +
  //   ColourPalette.FawBlue +
  //   " 20% , " +
  //   ColourPalette.FawBlueLight +
  //   " 100%)"
  //   display: "flex"
});

const CloudWrapper = glamorous.div({
  paddingTop: "40px",
  zIndex: "-1",
  position: "static",
  // overflow: "hidden",
  height: "70%"
});

// make the keyframes with glamor

const TextContainer = glamorous.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: FontSize.Header2,
  margin: "2px 40px",
  textAlign: "center",
  zIndex: "100",
  position: "relative"
  //   paddingBottom: "40px"
});

const ContactButton = glamorous.div({
  width: "fit-content",
  fontSize: FontSize.SubHeader,
  padding: "10px 15px",
  borderRadius: "10px",
  color: ColourPalette.FawBlue,
  transition: Transition.Normal,
  backgroundColor: ColourPalette.FawYellow,
  textAlign: "center",
  marginTop: "20px",
  ":hover": {
    border: "2px solid " + ColourPalette.FawYellowLight,
    backgroundColor: ColourPalette.FawYellowLight,
    cursor: "pointer",
    color: ColourPalette.FawBlueLight
  }
});

class Banner extends Component {
  onClick(loc) {
    window.location = "/" + loc;
  }
  render() {
    return (
      <BannerWrapper className="animate-gradient ">
        <CloudWrapper>
          <div class="x1">
            <div class="cloud-yellow" />
          </div>

          <div class="x2">
            <div class="cloud" />
          </div>

          <div class="x3">
            <div class="cloud" />
          </div>

          <div class="x4">
            <div class="cloud-yellow" />
          </div>

          <div class="x5">
            <div class="cloud-yellow" />
          </div>
        </CloudWrapper>
        <TextContainer>
          {/*className="text"> */}
          We help organisations bring their cloud applications to reality
          <ContactButton onClick={() => this.onClick("contact")}>
            Contact Us
          </ContactButton>
        </TextContainer>
      </BannerWrapper>
    );
  }
}

export default Banner;
